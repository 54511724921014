import axios from 'axios'

const whatsAppIcon = $('.whatsapp-icon')
const whatsAppChat = $('.whatsapp-chat')

whatsAppIcon.click(function(e) {
  e.stopPropagation()
  whatsAppChat.toggleClass('active')
})

$(document).click(function(e) {
  if (!whatsAppChat.is(e.target) && whatsAppChat.has(e.target).length === 0 && whatsAppChat.hasClass('active')) {
    whatsAppChat.removeClass('active')
  }
})

$('.whatsapp-chat-form input[name="phone"]').on('keyup', function() {
  $(this).val(phoneMask($(this).val().slice(0, 16), true))
})

$('.whatsapp-chat-form').on('submit', function(e) {
  e.preventDefault()

  const params = new URLSearchParams(window.location.search)

  const utm_campaign = params.get('utm_campaign') ?? null
  const utm_source = params.get('utm_source') ?? null
  const utm_medium = params.get('utm_medium') ?? null
  const utm_term = params.get('utm_term') ?? null
  const utm_content = params.get('utm_content') ?? null

  const action = $(this).attr('action')
  const formData = $(this).serializeArray()

  const { value: name } = formData[0]
  const { value: phone } = formData[1]
  const { value: interest } = formData[2]
  const { value: nonce } = formData[3]
  const { value: text } = formData[4]

  const button = $(this).find('button[type="submit"]')

  button.prop('disabled', true)

  axios.post(`${BASE_URL}/wp-json/wp/v2/whatsapp-leads`, {
    name,
    phone,
    interest,
    utm_campaign,
    utm_source,
    utm_medium,
    utm_term,
    utm_content,
    page_link: window.location.href,
  }).then(() => {
    window.location.href = `${action}?text=${text}`
  }).catch(() => {
    window.location.href = `${action}?text=${text}`
  }).finally(() => {
    window.location.href = `${action}?text=${text}`
    // button.prop('disabled', false)
  })
})
