window.BASE_URL = document.querySelector('meta[name="base_url"]').getAttribute('content')

// helpers
import './helpers/utils'

// common
import './common/scroll'

// components
import './components/featured-properties'
import './components/filter'
import './components/whatsapp-chat'

// partials
import './partials/carousel-posts'
import './partials/responsive-menu'
import './partials/responsive-menu'
import './partials/search-form'

// pages
import './pages/property'
import './pages/posts'
import './pages/broker'
import './pages/properties'
