'use strict';

$('body.blog > main > .featured-posts .owl-carousel').owlCarousel({
  loop: true,
  items: 1,
  stagePadding: 300,
  margin: 30,
  nav: true,
  responsive: {
    0: {
      stagePadding: 0,
    },
    520: {
      stagePadding: 180,
    },
    992: {
      stagePadding: 300,
    },
  },
})
