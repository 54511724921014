'use strict';

$('#open-menu').click(function () {
  $('#responsive-menu').addClass('active')
  $('body').addClass('no-scroll')
})

$('#close-menu, #responsive-menu > .backdrop').click(function () {
  $('#responsive-menu').removeClass('active')
  $('body').removeClass('no-scroll')
})
