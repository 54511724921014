window.moneyMask = (value) => {
  return parseFloat(value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
}

window.getPropertyLink = (property) => {
  let link = BASE_URL

  if (property.Status === 'Aluguel') {
    link += '/alugar/'
  } else {
    link += '/comprar/'
  }

  link += property.TituloSite.toLowerCase().replace('²', '2').replace(/[^\w\s-]/g, '').replace(/[\s-]+/g, '-')
  link += `-${property.Codigo.toLowerCase()}`

  return link
}

window.cellphoneMask = (cellphone, requiredPrefix = false) => {
  cellphone = cellphone.replace(/\D+/g, '')

  if (requiredPrefix)
    cellphone = cellphone.replace(/(\d{2})(\d)/, '($1) $2')

  return cellphone.replace(/(\d{1})(\d{4})(\d)/, '$1 $2-$3')
    .replace(/(-\d{4})\d+?$/, '$1')
}

window.telephoneMask = (telephone, requiredPrefix = false) => {
  telephone = telephone.replace(/\D+/g, '')

  if (requiredPrefix)
    telephone = telephone.replace(/(\d{2})(\d)/, '($1) $2')

  return telephone.replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(-\d{4})\d+?$/, '$1')
}

window.phoneMask = (phone, requiredPrefix = false) => {
  if (phone.length <= 14)
    return telephoneMask(phone, requiredPrefix)

  return cellphoneMask(phone, requiredPrefix)
}
