const toBRL = (value) => value.toLocaleString('pt-BR', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
})

$(document).ready(function() {
  if ($('.range-slider').length > 0) {
    $('.range-slider').each(function() {
      const $this = $(this)

      const $minPriceInput = $this.find('.min-price')
      const $maxPriceInput = $this.find('.max-price')

      $minPriceInput.on('input', function() {
        const min = parseInt(this.value)

        if (min + 100 >= parseInt($maxPriceInput.val())) {
          const max = min + 100
          $maxPriceInput.val(max)
          $this.find('.max-price-value').val(toBRL(max))
        }

        $this.find('.min-price-value').val(toBRL(min))
      })

      $maxPriceInput.on('input', function() {
        const max = parseInt(this.value)

        if (max - 100 <= parseInt($minPriceInput.val())) {
          const min = max - 100
          $minPriceInput.val(min)
          $this.find('.min-price-value').val(toBRL(min))
        }

        $this.find('.max-price-value').val(toBRL(max))
      })

      $this.find('.min-price-value').maskMoney({
        allowNegative: true,
        thousands:'.',
        decimal:',',
        affixesStay: false,
      }).on('keyup.maskMoney', function () {
        const value = parseFloat($(this).val().replace(/\./g, '').replace(',', '.'))
        $minPriceInput.val(value)
      })

      $this.find('.max-price-value').maskMoney({
        allowNegative: true,
        thousands:'.',
        decimal:',',
        affixesStay: false,
      }).on('keyup.maskMoney', function () {
        const value = parseFloat($(this).val().replace(/\./g, '').replace(',', '.'))
        $maxPriceInput.val(value)
      })
    })
  }

  if ($('#modal-filter').length > 0) {
    const handleChange = function () {
      const finality = $('input[name="finalidade"]:checked').val()

      const rentPricing = parseFloat($('#rent-pricing').val())
      const salePricing = parseFloat($('#sale-pricing').val())

      const $minPriceInput = $('.range-slider .min-price')
      const $maxPriceInput = $('.range-slider .max-price')

      if (finality === 'Aluguel') {
        $minPriceInput.attr('max', rentPricing)
        $maxPriceInput.attr('max', rentPricing).val(rentPricing)

        $('.max-price-value').val(toBRL(rentPricing))
      } else {
        $minPriceInput.attr('max', salePricing)
        $maxPriceInput.attr('max', salePricing).val(salePricing)

        $('.max-price-value').val(toBRL(salePricing))
      }

      $('.min-price-value').val(toBRL(1000))
    }

    $('input[name="finalidade"]').change(handleChange)
  }
})
