import * as $vistasoft from '../services/vistasoft'

console.log('Carregou js geral');

if ($('#featured-properties').length > 0) {
  $('#featured-properties').owlCarousel({
    loop: true,
    margin: 20,
    dots: false,
    autoWidth: false,
    responsiveClass: true,
    mouseDrag: false,
    nav: true,
    responsive: {
      0: {
        items: 1,
      },
      480: {
        items: 2,
      },
      992: {
        items: 3,
      },
      1198: {
        items: 4,
      },
    }
  })

  async function getProperty(content, properties, property, index) {
    const { data } = await $vistasoft.images(property.Codigo)

    const images = data?.Foto ? Object.values(data.Foto) : []

    content.push(`
      <div class="property">
        <div class="property__image">
          ${property.Lancamento === 'Sim' ? '<figcaption class="property__caption">Lançamento</figcaption>' : ''}

          ${images.length > 0 ? (`
            <div class="property-gallery">
              ${images.map((image, i) => `
                <figure class="image-item">
                  <a href="${getPropertyLink(property)}">
                    <img src="${i === 0 && image.Foto !== property.FotoDestaque ? property.FotoDestaque : image.Foto}" class="img-fluid" alt="${property.TituloSite}" />
                  </a>
                </figure>
              `).join('')}
            </div>
          `) : (`
            <figure class="image-item">
              <a href="${getPropertyLink(property)}">
                <img src="${property.FotoDestaquePequena.length > 0 ? property.FotoDestaquePequena : 'https://via.placeholder.com/330x270'}" class="img-fluid" alt="${property.TituloSite}" />
              </a>
            </figure>
          `)}
        </div>

        <div class="property__body">
          <div class="header-details mb-3">
            <div class="property-type">${property.CategoriaImovel}</div>

            <div class="property-price">
              ${moneyMask(property.Status === 'Aluguel' ? property.ValorLocacao : property.ValorVenda)}
            </div>
          </div>

          <div class="address mb-3">
            <h3>${property.Endereco}</h3>
            <span>${property.Bairro}, ${property.Cidade}</span>
          </div>

          <div class="footer-details">
            <div class="square-meters">
              <i class="icon icon-square-meter"></i> ${property.AreaPrivativa} m²
            </div>
            <div class="bedrooms">
              <i class="icon icon-bedroom"></i> ${property.Dormitorios} quartos
            </div>
          </div>
        </div>
      </div>
    `)

    index++

    if (properties[index]) {
      getProperty(content, properties, properties[index], index)
    } else {
      $('#featured-properties').trigger('replace.owl.carousel', content.join('')).trigger('refresh.owl.carousel')

      $('#featured-properties .property-gallery').owlCarousel({
        loop: true,
        items: 1,
        nav: true,
        dots: true,
        dotsEach: 5,
      })
    }
  }

  const getFeaturedProperties = async (goal = 'Aluguel') => {
    const content = []
    const { data } = await $vistasoft.featured({
      Status: goal,
    })
    const properties = Object.values(data)
    getProperty(content, properties, properties[0], 0)
  }

  getFeaturedProperties('Aluguel')

  $('.featured-properties .btn-link').on('click', function () {
    getFeaturedProperties($(this).data('goal'))
    $(this).addClass('active').siblings().removeClass('active')
  })
}
