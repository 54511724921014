'use strict';

$('select.bairro').select2({
  placeholder: 'Todos os bairros',
  closeOnSelect: false,
})

$('select.tipo').select2({
  placeholder: 'Todos os tipos',
  closeOnSelect: false,
})

$('select.quartos').select2({
  placeholder: 'Todos',
  closeOnSelect: false,
})

if ($('#modal-filter').length > 0) {
  const $form = $('#property-filter-form')

  const handleGoal = (goal) => {
    if (['Venda', 'Lancamento'].includes(goal)) {
      $form.find('.option-line-item.financing').show()
      $form.find('.option-line-item.pets').removeClass('last')
    } else {
      $form.find('.option-line-item.financing').hide()
      $form.find('.option-line-item.pets').addClass('last')
    }
  }

  $form.find('input[name="finalidade"]').on('change', function () {
    handleGoal($(this).val())

    if ($(this).val() === 'Aluguel') {
      $form.find('#financiamento-tanto_faz').attr('checked', true)
    }
  })

  $form.find('.btn-form-clear').on('click', function () {
    window.location.href = $form.attr('action')
  })

  handleGoal($form.find('input[name="finalidade"]:checked').val())
}
