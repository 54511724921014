'use strict';

const $el = $('.single-wp_router_page > main.main-alugar, .single-wp_router_page > main.main-comprar, .single-wp_router_page > main.main-imovel')

if ($el.length) {
  const carousel = $('.owl-carousel').owlCarousel({
    nav: true,
    loop: true,
    center: false,
    items: 3,
    margin: 20,
    responsive: {
      0: {
        items: 1,
      },
      480: {
        items: 2,
      },
      992: {
        items: 3,
      },
    },
  })

  Fancybox.bind('.owl-item:not(.cloned) a>img', {
    groupAll: true,
  });
}

if ($('.property-form').length) {
  $('.wpcf7-textarea').text(`Olá, tenho interesse no empreendimento: ${$('.page-header__title').text()}. Aguardo o contato. Obrigado.`)
  $('.wpcf7-hidden[name="url"]').val(window.location.href)

  const propertyForm = $('.property-form')

  $('.wpcf7-hidden[name="operacao"]').val(propertyForm.data('goal'))
  $('.wpcf7-hidden[name="codigo"]').val(propertyForm.data('code'))
  $('.wpcf7-hidden[name="produto"]').val(propertyForm.data('product'))
  $('.wpcf7-hidden[name="valor"]').val(propertyForm.data('price'))

  var tels = document.querySelectorAll(".wpcf7-phonetext");
  if (tels.length > 0) {
    tels.forEach(tel => {
      tel.setAttribute("maxlength", "13");
      tel.addEventListener('keyup', function (e) {
        if (e.key.includes("Arrow") || e.key === " " || e.key === "Backspace" || e.key === "Delete" || e.ctrlKey) {
          return false;
        }
        e.target.value = e.target.value.replace(/[^0-9 ]/g, "");
      });
      tel.addEventListener('keydown', function (e) {
        if (e.key.includes("Arrow") || e.key === " " || e.key === "Backspace" || e.key === "Delete" || e.ctrlKey) {
          return false;
        }
        function thisClear(string) {
          return string.replace(/[^0-9 ]/g, "");
        }
        let cleared = thisClear(e.target.value);
        if (cleared.length == 2) {
          this.value = cleared + ' ';
        }
        if (cleared.length == 7) {
          this.value = cleared + " ";
        }
        if (cleared.length == 12) {
          cleared = cleared.split("");
          cleared[7] = cleared[8];
          cleared[8] = " ";
          this.value = cleared.join("");
        }
      });
    });
  }
}

if ($('#sortBy').length) {
  $('#sortBy').on('change', function () {
    const url = new URL(window.location.href)
    url.searchParams.set('ordem', this.value)
    window.location.href = url.href
  })
}
