'use strict';

$(function () {
  if (window.location.hash && $(window.location.hash).length) {
    const offset = $(window.location.hash).offset().top - 120

    $('html, body').animate({
      scrollTop: offset,
    }, 500)
  }
})
