'use strict';

$('.single-collab > main > .testimonials .owl-carousel').owlCarousel({
  loop: true,
  items: 1,
  margin: 30,
  nav: true,
  responsive: {
    0: {
      items: 1,
    },
    520: {
      items: 1,
    },
    992: {
      items: 3,
    },
  },
})
