import axios from 'axios'

const key = 'c08c31d20a67dede0c1ec4fb48759dd2'
const API_URL = document.querySelector('meta[name="api_url"]').getAttribute('content')

export const fields = () => axios.get(`${API_URL}/imoveis/listarcampos`, {
  params: {
    key,
  },
})

export const featured = (filters = {}) => axios.get(`${API_URL}/imoveis/listar`, {
  params: {
    key,
    pesquisa: {
      fields: [
        'Codigo',
        'Vagas',
        'Dormitorios',
        'ValorLocacao',
        'AreaTotal',
        'Bairro',
        'Cidade',
        'Endereco',
        'Status',
        'TipoImovel',
        'TituloSite',
        'ValorVenda',
        'FotoDestaque',
        'FotoDestaquePequena',
        'CategoriaImovel',
        'AreaPrivativa',
        'DestaqueWeb',
      ],
      filter: {
        DestaqueWeb: 'Sim',
        ...filters,
      },
    },
  },
})

export const images = (code) => axios.get(`${API_URL}/imoveis/detalhes`, {
  params: {
    key,
    imovel: code,
    pesquisa: {
      fields: [
        {
          Foto: [
            'Foto',
          ],
        },
      ],
    }
  },
})

export const neightborhoods = () => axios.get(`${API_URL}/imoveis/listarConteudo`, {
  params: {
    key,
    pesquisa: {
      fields: [
        'Bairro',
      ],
      filter: {
        Cidade: 'Florianopolis',
      },
    },
  },
})

export const addressess = () => axios.get(`${API_URL}/imoveis/listarConteudo`, {
  params: {
    key,
    pesquisa: {
      fields: [
        'Bairro',
        'Endereco',
      ],
      filter: {
        Cidade: 'Florianopolis',
      },
    },
  },
})
